<template>
  <div>
    <b-card>
      <h1 class="text-center">{{ $t('lbl_welcome_to_hodo_e_commerce') }}</h1>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
    BLink
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style>
</style>
